import React, { useEffect } from 'react';
import { BASE_URL, SECRET_KEY_ENCRYPT } from '@utils/config';
import axios from 'axios';



const Maintenance = () => {


  const fetch = async () => {


    let config = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbiI6InNhaWRAbGFjYWlzc2UubWEiLCJwd2QiOiJzYWlkb3VhZGlsMzQ4MDAwIiwibGljZW5jZSI6IkxES0RKTEotRExLWkRaIiwiaWF0IjoxNzMxMzQzOTMxfQ.3REyluPUhhp5oqyfDO7Z5AHwyUl2qWJfzptWIukc9pQ",
      "X-identifier-account": "6",
      "X-identifier-val": "d1a717bcf199009c0b368ef0b3b1e7ded3"
    }

    var instance = axios.create();
    instance.defaults.headers.common["Authorization"] = config.Authorization

    instance.defaults.headers.common["X-identifier-val"] = "403"
    instance.defaults.headers.common["X-identifier-account"] = "6"

    try {
      const url = `${BASE_URL}depots/getAllDepots/403`;
      const response = await instance.get(url);
      console.log("fetch", response)
      if (response) {
        window.location.replace("/login")
      }

    } catch (error) {

    } finally {
      // setLoading(false);
    }




  }


  useEffect(() => {

    console.log("jdjdjdjdjdjd")

    fetch()



  }, [])




  return (


    <div className='container__'>
      <div className="min-h-screen flex items-center justify-center bg-gray-100">

        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <div className="animation_main">
            <div className="one_main spin-one"></div>
            <div className="two_main spin-two"></div>
            <div className="three_main spin-one"></div>
          </div>

          <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#2d3748', marginBottom: '0.5rem' }}>En maintenance</h1>
          <p style={{ color: '#4a5568', marginBottom: '1rem', fontSize: '1.25rem', paddingBottom: '1rem' }}>
            Notre site est actuellement en maintenance. Nous reviendrons bientôt. Merci de votre compréhension.
          </p>
          <a href="/login" style={{ color: '#f56565', textDecoration: 'underline', paddingBottom: '1rem', display: 'block' }}>
            Ou essayez de vous connecter plus tard
          </a>


        </div>

      </div>

    </div>




  );
};

export default Maintenance;